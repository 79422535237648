import AnalysisSection from './AnalysisSection';
import Heading1 from '../../components/Heading1';
import vidtao from '../../images/vidtao.png';

function ChatPage() {
  return(
    <div className="container mx-auto">
      <div className="flex flex-col items-center">
      <img className="w-64 sm:96 m-4 self-start" src={vidtao} alt="logo" />
      <Heading1 className="mt-2 mb-4 sm:mb-8 md:w-[32rem]">What’s Your YouTube Ad <span className="underline">Creative Score</span>?</Heading1>
      <p className="text-lg md:text-2xl text-center mb-6">Enter your YouTube ad video url below to find out…</p>
      <AnalysisSection />
      <div className={`w-11/12 sm:w-10/12 border-t-4 border-[#434343]`}>
        <p className="my-8 text-xl text-center">inceptly.com</p>
      </div>
      </div>
    </div>
  );
}

export default ChatPage;

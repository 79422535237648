import { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import { getTopSimilarAdVideos, getCreativeAnalysis, getScreenshotDescriptions, getHookEvaluation } from '../../store';
import useThunk from '../../hooks/use-thunks';
import InputBox from '../../components/InputBox';
import Button from '../../components/Button';
import MultilineText from '../../components/MultilineText';
import VideoDetailsCheckbox from "./VideoDetailsCheckbox";
import { APP_STATUS } from '../../constants';
import ScreenshotDescriptionTable from "./ScreenshotDescriptionTable";

const STEPS = [
  [APP_STATUS.SIMILAR_AD_VIDEOS_LOADING, APP_STATUS.SIMILAR_AD_VIDEOS_SUCCESS, APP_STATUS.SIMILAR_AD_VIDEOS_FAILED],
  [APP_STATUS.SCREENSHOT_DESCRIPTIONS_LOADING, APP_STATUS.SCREENSHOT_DESCRIPTIONS_SUCCESS, APP_STATUS.SCREENSHOT_DESCRIPTIONS_FAILED],
  [APP_STATUS.HOOK_EVALUATION_LOADING, APP_STATUS.HOOK_EVALUATION_SUCCESS, APP_STATUS.HOOK_EVALUATION_FAILED],
  [APP_STATUS.CREATIVE_ANALYSIS_LOADING, APP_STATUS.CREATIVE_ANALYSIS_SUCCESS, APP_STATUS.CREATIVE_ANALYSIS_FAILED]
];

function AnalysisSection() {
  const analysis = useSelector(state => state.analysis);
  const [doGetTopSimilarAdVideos] = useThunk(getTopSimilarAdVideos);
  const [doGetCreativeAnalysis] = useThunk(getCreativeAnalysis);
  const [doGetScreenshotDescriptions] = useThunk(getScreenshotDescriptions)
  const [doGetHookEvaluation] = useThunk(getHookEvaluation)
  const [videoUrl, setVideoUrl] = useState('');
  const [submittedVideoUrl, setSubmittedVideoUrl] = useState('');
  const [selectedVideoId, setSelectedVideoId] = useState('');
  const [submittedVideoId, setSubmittedVideoId] = useState('');
  const inputRef = useRef(null);

  const handleUrlSubmit = event => {
    event.preventDefault();
    let url = videoUrl.trim();
    if (url) {
      setSubmittedVideoUrl(url)
      setSelectedVideoId('');
      doGetTopSimilarAdVideos(url);
    }
  };

  const handleGetDescriptions = event => {
    event.preventDefault();
    if (selectedVideoId) {
      setSubmittedVideoId(selectedVideoId)
      doGetScreenshotDescriptions({ videoUrl: submittedVideoUrl, videoId: selectedVideoId })
    }
  };

  const handleGetHookEvaluation = event => {
    event.preventDefault();
    doGetHookEvaluation({ videoUrl: submittedVideoUrl, videoId: submittedVideoId })
  };

  const handleGetAnalysis = event => {
    event.preventDefault();
    doGetCreativeAnalysis({ videoUrl: submittedVideoUrl, videoId: submittedVideoId })
  };

  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-11/12 sm:w-10/12">
        <form className="flex flex-col items-center w-full" onSubmit={handleUrlSubmit}>
          <div className="relative w-full mb-8">
            <InputBox id="video-url" ref={inputRef} className="w-full text-sm md:text-md lg:text-xl" value={videoUrl} onChange={event => setVideoUrl(event.target.value)} required />
            {videoUrl.length > 0 ||
            <div className="absolute inset-3 cursor-text flex justify-center items-center" onClick={() => inputRef.current.focus()}>
              <p className="text-sm md:text-md lg:text-xl text-center">Enter your <span className="font-bold">YouTube ad video url here</span> & click “Get My Score” to begin</p>
            </div>}
          </div>
          <Button className="mb-10 px-6 py-8 sm:px-8 sm:py-9 font-bold text-xl sm:text-3xl" primary rounded disabled={analysis.currentStatus === APP_STATUS.SIMILAR_AD_VIDEOS_LOADING}>Get My Score</Button>
        </form>

        {[...STEPS[0], ...STEPS[1], ...STEPS[2], ...STEPS[3]].includes(analysis.currentStatus) && <div className="w-full break-words mb-5">
          <hr className="mb-5 border-0 h-1 bg-[#434343]" />

          {analysis.currentStatus === APP_STATUS.SIMILAR_AD_VIDEOS_LOADING && <>
            <p className="text-center text-[#305BE8] my-10">One moment. We are working on it.</p>
          </>}

          {analysis.currentStatus === APP_STATUS.SIMILAR_AD_VIDEOS_FAILED && <>
            <p className="text-lg text-red-500 mb-5">Failed to process your video url</p>
            <p className="text-gray-500">{analysis.errorMessage}</p>
          </>}

          {[APP_STATUS.SIMILAR_AD_VIDEOS_SUCCESS, ...STEPS[1], ...STEPS[2], ...STEPS[3]].includes(analysis.currentStatus) && <>
            <p className="mb-10 text-lg sm:text-2xl font-bold">Here are the top 5 “high performing” YouTube ads that are most similar to your ad. Which one do you want to compare your ad with? (select one and click “Continue”)</p>
            <form onSubmit={handleGetDescriptions}>
              {analysis.similarAdVideos[0].map((adVideo, index) => {
                return (<div key={index}>
                  <VideoDetailsCheckbox adVideo={adVideo} name="video-id" onChange={event => setSelectedVideoId(event.target.value)} />
                </div>);
              })}

              {analysis.similarAdVideos[1].length > 0 && <>
                <p className="text-lg my-5">Other ads from this channel</p>
                {analysis.similarAdVideos[1].map((adVideo, index) => {
                  return (<div key={index}>
                    <VideoDetailsCheckbox adVideo={adVideo} name="video-id" onChange={event => setSelectedVideoId(event.target.value)} />
                  </div>);
                })}
              </>}
              <Button className="my-10 mx-auto px-6 py-8 sm:px-8 sm:py-9 font-bold text-xl sm:text-3xl" primary rounded disabled={analysis.currentStatus === APP_STATUS.SCREENSHOT_DESCRIPTIONS_LOADING || !selectedVideoId}>Continue...</Button>
            </form>
          </>}
        </div>}

        {[...STEPS[1], ...STEPS[2], ...STEPS[3]].includes(analysis.currentStatus) && <div className="w-full break-words mb-5">
          <hr className="mb-5 border-0 h-1 bg-[#434343]" />

          {analysis.currentStatus === APP_STATUS.SCREENSHOT_DESCRIPTIONS_LOADING && <>
            <p className="text-center text-[#305BE8] my-10">One moment. We are generating the image descriptions.</p>
          </>}

          {analysis.currentStatus === APP_STATUS.SCREENSHOT_DESCRIPTIONS_FAILED && <>
            <p className="text-lg text-red-500 mb-5">Failed to generate screenshot descriptions.</p>
            <p className="text-gray-500">{analysis.errorMessage}</p>
          </>}

          {[APP_STATUS.SCREENSHOT_DESCRIPTIONS_SUCCESS, ...STEPS[2], ...STEPS[3]].includes(analysis.currentStatus) && <>
            <div className="mb-10">
              {analysis.descTables && analysis.descTables[0] ?
                <ScreenshotDescriptionTable descTable={analysis.descTables[0]} /> :
                <p className="text-lg text-red-500">Failed to generate your image descriptions.</p>}
            </div>

            <div className="mb-5">
              {analysis.descTables && analysis.descTables[1] ?
                <ScreenshotDescriptionTable descTable={analysis.descTables[1]} /> :
                <p className="text-lg text-red-500">Failed to generate the image descriptions of the video you selected.</p>}
            </div>

            <form onSubmit={handleGetHookEvaluation}>
              <Button className="my-10 mx-auto px-6 py-8 sm:px-8 sm:py-9 font-bold text-xl sm:text-3xl" primary rounded disabled={analysis.currentStatus === APP_STATUS.HOOK_EVALUATION_LOADING}>Continue...</Button>
            </form>
          </>}

        </div>}

        {[...STEPS[2], ...STEPS[3]].includes(analysis.currentStatus) && <div className="w-full break-words mb-5">
          <hr className="mb-5 border-0 h-1 bg-[#434343]" />

          {analysis.currentStatus === APP_STATUS.HOOK_EVALUATION_LOADING && <>
            <p className="text-center text-[#305BE8] my-10">One moment. We are generating hook evaluation.</p>
          </>}

          {analysis.currentStatus === APP_STATUS.HOOK_EVALUATION_FAILED && <>
            <p className="text-lg text-red-500 mb-5">Failed to evaluate hook.</p>
            <p className="text-gray-500">{analysis.errorMessage}</p>
          </>}

          {[APP_STATUS.HOOK_EVALUATION_SUCCESS, ...STEPS[3]].includes(analysis.currentStatus) && <>
            {analysis.hookEvaluationResults ?
              <div className="my-5">
                {analysis.hookEvaluationResults.map((result, index) => {
                  return (<div key={index} className="mb-12">
                    <p>Ad {index + 1}:</p>
                    {result.map((evaluation, index) => {
                      return (<div key={index} className="mb-8">
                        <p className="font-bold my-4">{evaluation['title']}: {evaluation['rating']}/{evaluation['total']}</p>
                        <MultilineText trimEmptyLines={true}>{evaluation['explanation']}</MultilineText>
                      </div>);
                    })}
                  </div>);
                })}
              </div> :
              <p className="text-lg text-red-500">Failed to generate your hook evaluation.</p>}

            <form onSubmit={handleGetAnalysis}>
              <Button className="my-10 mx-auto px-6 py-8 sm:px-8 sm:py-9 font-bold text-xl sm:text-3xl" primary rounded disabled={analysis.currentStatus === APP_STATUS.CREATIVE_ANALYSIS_LOADING}>Continue...</Button>
            </form>
          </>}

        </div>}

        {STEPS[3].includes(analysis.currentStatus) && <div className="w-full break-words mb-5">
          <hr className="mb-5 border-0 h-1 bg-[#434343]" />

          {analysis.currentStatus === APP_STATUS.CREATIVE_ANALYSIS_LOADING && <>
            <p className="text-center text-[#305BE8] my-10">One moment. We are working on the creative analysis.</p>
          </>}

          {analysis.currentStatus === APP_STATUS.CREATIVE_ANALYSIS_FAILED && <>
            <p className="text-lg text-red-500 mb-5">Failed to get creative analysis.</p>
            <p className="text-gray-500">{analysis.errorMessage}</p>
          </>}

          {analysis.currentStatus === APP_STATUS.CREATIVE_ANALYSIS_SUCCESS && <>
            <div className="mb-10">
              {analysis.videoInfoItems && analysis.videoInfoItems[0] && <VideoDetailsCheckbox adVideo={analysis.videoInfoItems[0]} />}
              {analysis.creativeAnalysisTextItems && analysis.creativeAnalysisTextItems[0] ?
                <>
                  <ReactMarkdown>{analysis.creativeAnalysisTextItems[0]}</ReactMarkdown>
                </> :
                <p className="text-lg text-red-500">Failed to get your creative analysis.</p>}
            </div>

            <div className="mb-10">
              {analysis.videoInfoItems && analysis.videoInfoItems[1] && <VideoDetailsCheckbox adVideo={analysis.videoInfoItems[1]} />}
              {analysis.creativeAnalysisTextItems && analysis.creativeAnalysisTextItems[1] ?
                <>
                  <ReactMarkdown>{analysis.creativeAnalysisTextItems[1]}</ReactMarkdown>
                </> :
                <p className="text-lg text-red-500">Failed to get creative analysis of the video you chose.</p>}
            </div>
          </>}
        </div>}

      </div>
    </div>
  );
}

export default AnalysisSection;
